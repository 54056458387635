/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { message } from "antd";
import { logOutThunk } from "store/auth/slice/authSlice";

const ApiMiddleware401: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (isRejectedWithValue(action) && (action.payload?.originalStatus === 401 || action.payload?.status === 401)) {
      message.error({
        content: "Session timed out. Please log in to continue.",
        key: "updatable",
        duration: 3,
      });
      dispatch(logOutThunk() as any);
    }

    return next(action);
  };

export default ApiMiddleware401;
