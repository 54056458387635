import { AddressZero } from "@ethersproject/constants";
import { useGetUserQuery } from "store/auth/api/userapi";
import { setAuth } from "store/auth/slice/authSlice";
import { PrivilegeResponse, UserResponse } from "store/auth/types/types";
import { useAppDispatch, useAppSelector } from "./hooks";

export const useAuth = () => {
  const authData = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserQuery(authData.userid, { skip: !authData.userid });
  if (authData.token !== "") return { isLoggedIn: true, expired: false };
  const token = authData.token !== "" ? authData.token : localStorage.getItem("token");
  if (token) {
    const user: UserResponse = userData ?? JSON.parse(localStorage.getItem("user") ?? "");
    const authObj = {
      token: token,
      userid: user?._id ?? "",
      userbaddress: !user || user?.baddress === "" ? AddressZero : user.baddress.toLowerCase(),
      org: user?.org,
      roles: user?.roles ?? [],
      userpreferences: user?.userpreferences ?? [],
      actionPrivs: new Array<string>(),
    };
    const actionPrivs = new Array<PrivilegeResponse>();
    authObj.roles.forEach((role) => {
      actionPrivs.push(...(role.privs.filter((priv) => priv.type === "Action")?.flat() ?? []));
    });
    authObj.actionPrivs = actionPrivs.map(({ name }) => name);

    dispatch(setAuth(authObj));
    return { isLoggedIn: true, expired: false };
    // }
  }
  return { isLoggedIn: false, expired: true };
};
